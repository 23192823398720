import Arrow from "../images/marketing/arrow.svg"
import Envelope from "../images/marketing/envelope.svg"
import Graph from "../images/marketing/graph.svg"
import Settings from "../images/marketing/settings.svg"
import Heart from "../images/marketing/heart.svg"
import Person from "../images/marketing/person.svg"

export const ACCORDION_TECHNOLOGIES_DATA = [
  {
    heading: "PPC",
    src: Arrow,
    text: "Vyšší tržby a\u00A0nižší náklady. Zajistíme vám relevantní prokliky a\u00A0návštěvy končící nákupem. Umíme Google Ads, Sklik i\u00A0Bing. Výkonnostně, brandově a\u00A0s\u00A0vysokou mírou automatizace. Výsledky vám budeme pravidelně reportovat.",
  },
  {
    heading: "Automatizace",
    src: Settings,
    text: "Nechte nudné manuální činnosti robotům. Zmapujeme, jak v rámci digitálu pracujete, a\u00A0navrhneme automatizace. Ať už s\u00A0využitím existujících nástrojů, nebo skriptů a\u00A0menších aplikací na míru, které pro vás i\u00A0vytvoříme.",
  },
  {
    heading: "Sociální sítě",
    src: Heart,
    text: "Facebook, Instagram, LinkedIn, ale i\u00A0Pinterest, TikTok nebo Waze. Všude tam budete výraznější než konkurence. Zařídíme vám reklamu, co bude bavit i\u00A0prodávat.",
  },
  {
    heading: "Mailing",
    src: Envelope,
    text: "Komunikujte se zákazníky co nejosobněji a\u00A0automatizovaně. Připravíme blastové rozesílky, nastavíme automatické i\u00A0transakční e-maily. Nakódujeme šablony přímo na tělo a\u00A0naučíme vás s\u00A0nimi pracovat.",
  },
  {
    heading: "Datová analytika",
    src: Graph,
    text: "Plavete v\u00A0datech, ale nevíte, co s\u00A0nimi? Vytvoříme vám datový plán, implementujeme měření, ale primárně nastavíme vyhodnocování. Provedeme vás vším od webové analytiky přes marketingovou až po tu produktovou a\u00A0zákaznickou.",
  },
  {
    heading: "HR marketing",
    src: Person,
    text: "Ulovíme vysněné kolegy a\u00A0kolegyně. Světem HR kampaní vás provedeme komplexně od výzkumu přes tvorbu stránek až po samotný nábor.",
  },
]
