import Pezlarova from "../images/reference/kiwi/pezlarova.jpg"
import Helcel from "../images/reference/helcel/martin.jpg"
import FEKTVUT from "../images/reference/fektvut/avatar.jpg"
import Zezula from "../images/reference/snowboard-zezula/reaction-marketing.png"

export const USERS_REFERENCES = [
  {
    personName: "Lucie Pezlarová",
    img: Pezlarova,
    position: "Head of Analytics, Kiwi.com",
    id: 1,
    text: "S\u00A0Adamem spolupracujeme již od roku 2018. Pomáhá analytikům i\u00A0marketingovým manažerům Kiwi.com nastavit vhodné postupy pro vyhodnocování celého portfolia marketingových aktivit tak, aby výsledný reporting dával smysl a\u00A0hodnoty byly použitelné pro rozhodování. Adam je předním expertem a\u00A0navíc důvěryhodným a\u00A0organizovaným partnerem pro diskusi; spolupráce s\u00A0ním si velmi vážím.",
  },
  {
    personName: "Martin Helcel",
    img: Helcel,
    position: "jednatel Helcel nábytek",
    id: 2,
    text: "Požadavek na stabilní projektové řízení byl ze strany igloonetu splněn na 100\u00A0%. Považuji za velkou výhodu, že na projektu pracují stabilně stejní lidé, a\u00A0vzájemně tak vždy víme, jaké jsou naše cíle. Velmi také oceňuji postřehy k možnému vylepšení celého projektu.",
  },
  {
    personName: "Martin Zezula",
    img: Zezula,
    position: "ředitel Snowboard Zezula",
    id: 3,
    text: "Na igloonetu se mi líbí spojení profesionality s\u00A0neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z\u00A0paty.",
  },
  {
    personName: "doc. Ing. Jiří Háze, Ph.D.",
    img: FEKTVUT,
    position: "proděkan pro vnější vztahy a\u00A0zahraniční styky",
    id: 4,
    text: "Spolupráce se společností igloonet je z\u00A0našeho pohledu velmi přínosná. Musíme vyzdvihnout profesionalitu pracovníků firmy, jejich kreativitu a\u00A0nápady, které pomáhají fakultě řešit problém nedostatku dobrých studentů na technické univerzitě. Díky spolupráci se fakultě daří uchazeče o\u00A0studium oslovovat atraktivním způsobem a\u00A0se zapálením pro elektrotechniku.",
  },
]
