import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import ChatBubble from "../../icons/chat_bubble.svg"
import GraphIcon from "../../icons/graph.svg"
import MonitorIcon from "../../icons/monitor.svg"

const Wrapper = styled(Container)`
  padding: 5rem 0;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 5.25rem 0 12rem;

    .border-around {
      border-left: 3px solid ${(props) => props.theme.color.beige300};
      border-right: 3px solid ${(props) => props.theme.color.beige300};
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    width: 80%;
    max-width: 90%;
  }
`

const ColWrapper = styled(Col)`
  line-height: 2rem;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 3rem;

  &:after {
    content: "";
    display: block;
    text-align: center;
    justify-content: center;
    background-color: ${(props) => props.theme.color.blue300};
    margin: 5px auto;
    width: 20px;
    height: 3px;
  }
`

const InformationsBlock: React.FC = () => {
  return (
    <Wrapper fluid className="d-flex flex-column text-center">
      <Row>
        <ColWrapper md={4} className="py-3">
          <ChatBubble width={120} height={90} />
          <h3 className="py-3">Podpora marketingového týmu</h3>
          <WrapperInner>Mentoring</WrapperInner>
          <WrapperInner>Automatizace</WrapperInner>
          <WrapperInner>Školení na míru</WrapperInner>
          <WrapperInner>Nastavení analytiky a měření</WrapperInner>
          <WrapperInner>Propojování interních týmů</WrapperInner>
          <WrapperInner>Marketingová strategie</WrapperInner>
          <WrapperInner>Podpora managementu</WrapperInner>
          <WrapperInner>Výběrová řízení</WrapperInner>
        </ColWrapper>

        <ColWrapper md={4} className="border-around py-3">
          <GraphIcon width={120} height={90} />
          <h3 className="py-3">Analýzy a výzkum</h3>
          <WrapperInner>Analýza trhu</WrapperInner>
          <WrapperInner>Mapování konkurence</WrapperInner>
          <WrapperInner>Expanze do zahraničí</WrapperInner>
          <WrapperInner>Datová analytika</WrapperInner>
          <WrapperInner>UX/CRO</WrapperInner>
          <WrapperInner>Příprava workshopů</WrapperInner>
          <WrapperInner>Tvorba person</WrapperInner>
          <WrapperInner>Cesta zákazníka</WrapperInner>
        </ColWrapper>

        <ColWrapper md={4} className="py-3">
          <MonitorIcon width={120} height={90} />
          <h3 className="py-3">Online marketing</h3>
          <WrapperInner>PPC</WrapperInner>
          <WrapperInner>Sociální sítě</WrapperInner>
          <WrapperInner>Search / Vyhledávače</WrapperInner>
          <WrapperInner>Shopping</WrapperInner>
          <WrapperInner>Mailing</WrapperInner>
          <WrapperInner>Zbožové srovnávače</WrapperInner>
          <WrapperInner>SEO</WrapperInner>
          <WrapperInner>RTB</WrapperInner>
        </ColWrapper>
      </Row>
    </Wrapper>
  )
}

export default InformationsBlock
