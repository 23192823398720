import Image1 from "../images/marketing/reference_image_1.svg"
import Image2 from "../images/marketing/reference_image_2.svg"
import Image3 from "../images/marketing/reference_image_3.svg"
import KiwiLogo from "../images/reference/kiwi/logo.png"
import IFELogo from "../images/reference/ife/logoife.png"
import SNBZLogo from "../images/reference/snowboard-zezula/logo.png"
import { already } from "@igloonet-web/shared-ui"

export const REFERENCE_BIG_ICON_DATA = [
  {
    src: Image1,
    alt: "",
    specification: "mentoring",
    company: "Kiwi.com",
    text: "Pro Kiwi.com jsme začali konzultacemi ohledně propojování marketingu s\u00A0daty a\u00A0analytiky. Konzultace jsme později rozšířili až k\u00A0výkonnostním kampaním na socálních sítích a\u00A0mailingu.",
    link: "marketing/reference/kiwi",
    logo: KiwiLogo,
    logoAlt: "",
  },
  {
    src: Image2,
    alt: "",
    specification: "marketingová strategie",
    company: "SNOWBOARD ZEZULA",
    text: `Se snowboarďáky děláme už ${already(
      2008
    )}. Začali jsme jako vývojářská výpomoc a\u00A0postupně jsme se dostali až ke kompletní správě marketingu a\u00A0k\u00A0výstavbě nového e‑shopu na míru.`,
    link: "marketing/reference/snowboard-zezula",
    logo: SNBZLogo,
    logoAlt: "",
  },
  {
    src: Image3,
    alt: "",
    specification: "HR marketing",
    company: "IFE",
    text: "Pro IFE pracujeme na komplexním řešení jejich HR marketingu. Zvýšili jsme příliv životopisů v\u00A0řádu desítek a\u00A0návštěvnost kariérních stránek v\u00A0řádu tisíců měsíčně.",
    link: "marketing/reference/ife",
    logo: IFELogo,
    logoAlt: "",
  },
]
