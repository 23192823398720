import React from "react"
import {
  ContactForm,
  Layout,
  ReferencesBigIcon,
  SliderMarketing,
} from "../components"
import {
  already,
  Banner,
  BannerStyle,
  SectionWrapper,
  Slider,
  Link,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"
import { USERS_REFERENCES } from "../model/users-references"
import { PERSON_SLIDER } from "../model/person-slider-marketing"
import { ACCORDION_TECHNOLOGIES_DATA } from "../model/accordion-technologies-data"
import { REFERENCE_BIG_ICON_DATA } from "../model/reference-big-icon-data"
import InformationsBlock from "../components/marketing/three-informations-blog"
import { Helmet } from "react-helmet"
import Accordion from "../components/accordion"
import PenguinsPuzzle from "../images/marketing/penguins_puzzle_game.svg"
import { Container } from "reactstrap"
import ReferenceImageText from "../components/reference-detail/reference-image-text"
import HRMktBanner from "../images/marketing/hr_marketing_banner.svg"

const Marketing = (): React.ReactNode => {
  return (
    <Layout>
      <Helmet>
        <title>
          Online marketing – pomůžeme nastartovat váš byznys | igloonet
        </title>
        <meta
          name="description"
          content="Pomůžeme vašemu byznysu vydělávat. Tvoříme reklamní kampaně a marketingové strategie na míru."
        />
      </Helmet>

      <Banner
        style={BannerStyle.Marketing}
        orangeHeading="Vezmeme váš marketing"
        blackHeading="pod své ploutve"
        text={`Komplexně. Od výzkumu přes kampaně až po datovou analytiku. Fungujeme po celé republice už přes ${already(
          2007
        )} . Ať už mořem marketingu proplouváte sami, nebo kormidlujete větší interní tým, postrčíme vás o\u00A0námořní míle kupředu.`}
      />

      <Container className="px-0 mt-3">
        <ReferenceImageText
          right
          img={HRMktBanner}
          alt=""
          width="600"
          height="666"
          svgImage
        >
          <h2>HR marketing od marketérů</h2>
          <p style={{ lineHeight: "2rem" }}>
            V&nbsp;online světě se pohybujeme už {already(2007)}. Pomáháme
            firmám i&nbsp;školám získat uchazeče s&nbsp;využitím moderních
            technik digitálního marketingu.
          </p>
          <br />
          <Button to="/hr-marketing" style={ButtonStyle.Primary}>
            ⟶ Více o&nbsp;HR marketingu
          </Button>
        </ReferenceImageText>
      </Container>

      <InformationsBlock />
      <Accordion
        mainTitle="Na co se zaměřujeme?"
        data={ACCORDION_TECHNOLOGIES_DATA}
        mainImageMarketing={PenguinsPuzzle}
      />

      <SectionWrapper>
        <ReferencesBigIcon
          heading="Na čem pracujeme"
          data={REFERENCE_BIG_ICON_DATA}
        />
        <div className="d-flex justify-content-center mt-3">
          <Link to="/marketing/reference">Další reference</Link>
        </div>
      </SectionWrapper>

      <SliderMarketing data={USERS_REFERENCES} />

      <Slider title={"Poznejte náš tým"} data={PERSON_SLIDER} />
      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Marketing
